import React, { useState } from 'react';

import { Refresh } from 'iconsax-react';
import { useQuery } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useCore } from '@hooks';

import SuccessModal from '@components/SuccessModal';

import { enums } from '@utils';

import { CustomButton } from './styles';

interface SyncButtonProps {
  taskId?: string;
  disabled: boolean;
  onRequest: () => void;
  onComplete: () => void;
}

const SyncButton: React.FC<SyncButtonProps> = ({
  taskId,
  disabled,
  onRequest,
  onComplete,
}) => {
  const { getTask } = useCore();
  const { addToast } = useToast();

  const [isModalErroOpen, setIsModalErroOpen] = useState(false);

  useQuery(['task', taskId], () => getTask(taskId!), {
    onSuccess: (data) => {
      if (data.includes(enums.TaskStatus.SUCCESS)) {
        onComplete();
        addToast('Sincronização feita com sucesso', 'success');
      } else if (data[1] === enums.TaskStatus.FAILURE) {
        onComplete();
        setIsModalErroOpen(true);
      }
    },
    enabled: !!taskId,
    refetchInterval: 5000,
  });

  return (
    <>
      <CustomButton
        variant="outline"
        syncing={!!taskId}
        onClick={onRequest}
        disabled={disabled || !!taskId}
      >
        <Refresh />
        {taskId ? 'Sincronizando...' : 'Sincronizar'}
      </CustomButton>
      <SuccessModal
        isOpen={isModalErroOpen}
        onClose={() => setIsModalErroOpen(false)}
        title="Falha ao sincronizar"
        message="Ocorreu um erro ao sincronizar os anuncios com a integração. Caso o erro persista, entre em contato com o suporte"
      />
    </>
  );
};

export default SyncButton;
