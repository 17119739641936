import React, { forwardRef, useImperativeHandle } from 'react';

import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import Input from '@components/Input';
import Label from '@components/Label';

import { errors } from '@utils';

const Lua4Config: React.ForwardRefRenderFunction<
  FormStepRef,
  IntegrationConfigProps
> = ({ initialValues, requestIntegrationConfiguration }, ref) => {
  const formikInitialValues: FormValuesIntegrationConfig = {
    url: '',
    ...initialValues,
  };

  const formikValidationSchema = yup.object().shape({
    url: yup.string().url(errors.urlError).required(errors.required),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => requestIntegrationConfiguration.mutate(values),
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  return (
    <FormikProvider value={formik}>
      <FormGroup>
        <Label htmlFor="url">Url</Label>
        <Input
          type="text"
          name="url"
          placeholder="Ex: https://minhaurl.com.br"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.url}
          invalidValue={!!formik.touched.url && !!formik.errors.url}
        />
        <FormError name="url" />
      </FormGroup>
    </FormikProvider>
  );
};

export default forwardRef(Lua4Config);
