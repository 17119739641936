import { useCallback } from 'react';

import { phone } from 'b2utils';
import { StoreType } from 'utils/enums';

import { useApi, endpoints } from '@contexts/Api';

const useStores = () => {
  const { request } = useApi();

  const generateStoreInfo = useCallback((data: FormValuesStore) => {
    const phoneData = phone.toObject(data.phone);

    return {
      name: data.type === StoreType.PHYSICAL ? undefined : data.storeName,
      phone: {
        country_code: phoneData.countryCode ?? '55',
        area_code: phoneData.areaCode,
        number: phoneData.number,
      },
      phone_is_whatsapp: data.phoneIsWhatsapp,
      address: {
        zip_code: data.zipCode.replace(/\D/g, ''),
        street: data.street,
        number: data.number,
        district: data.district,
        city: data.city.id,
        additional_info: data.additionalInfo,
      },
      document: data.document.replace(/\D/g, ''),
    };
  }, []);

  const listStores = useCallback(
    async <StoreFields extends keyof Store>(params: StoresQuery) => {
      const response = await request<Paginated<Store, StoreFields>>({
        method: 'get',
        url: endpoints.stores.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search || undefined,
          type: params.type,
          ordering: params.ordering || undefined,
          created__lte: params.createdLte || undefined,
          created__gte: params.createdGte || undefined,
          is_active: params.isActive,
        },
      });

      return response.data;
    },
    [request]
  );

  const getStore = useCallback(
    async (id: number) => {
      const response = await request<Store>({
        method: 'get',
        url: endpoints.stores.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const inactivateStore = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.stores.inactivate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const activateStore = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.stores.activate.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const createStore = useCallback(
    async (data: FormValuesClient) => {
      const response = await request<Store>({
        method: 'post',
        url: endpoints.stores.post,
        data: {
          owner: {
            name: data.name,
            email: data.email,
          },
          ...generateStoreInfo(data),
        },
      });

      return response.data;
    },
    [generateStoreInfo, request]
  );

  const updateStore = useCallback(
    async (id: number, data: FormValuesStore) => {
      const response = await request<Store>({
        method: 'patch',
        url: endpoints.stores.patch.replace(':id', id.toString()),
        data: generateStoreInfo(data),
      });

      return response.data;
    },
    [generateStoreInfo, request]
  );

  const listFaq = useCallback(
    async <FaqFields extends keyof Faq>(params: FaqQuery) => {
      const response = await request<Paginated<Faq, FaqFields>>({
        method: 'get',
        url: endpoints.stores.faq.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search || undefined,
          ordering: params.ordering || undefined,
        },
      });

      return response.data;
    },
    [request]
  );

  const getFaq = useCallback(
    async (id: number) => {
      const response = await request<Faq>({
        method: 'get',
        url: endpoints.stores.faq.get.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const createFaq = useCallback(
    async (data: FormValuesFaqInfo) => {
      const response = await request<Faq>({
        method: 'post',
        url: endpoints.stores.faq.post,
        data,
      });

      return response.data;
    },
    [request]
  );

  const updateFaq = useCallback(
    async (id: number, data: FormValuesFaqInfo) => {
      const response = await request<Faq>({
        method: 'patch',
        url: endpoints.stores.faq.patch.replace(':id', id.toString()),
        data,
      });

      return response.data;
    },
    [request]
  );

  const deleteFaq = useCallback(
    async (id: number) => {
      const response = await request<void>({
        method: 'delete',
        url: endpoints.stores.faq.delete.replace(':id', id.toString()),
      });

      return response.data;
    },
    [request]
  );

  const listCustomers = useCallback(
    async <CustomerFields extends keyof Customer>(params: CustomerQuery) => {
      const response = await request<Paginated<Customer, CustomerFields>>({
        method: 'get',
        url: endpoints.stores.customers.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search || undefined,
          ordering: params.ordering || undefined,
          store_id: params.store?.value || undefined,
          start_date: params.startDate,
          end_date: params.endDate,
        },
      });

      return response.data;
    },
    [request]
  );

  const getCustomer = useCallback(
    async (customer: number) => {
      const response = await request<Customer>({
        method: 'get',
        url: endpoints.stores.customers.get.replace(':id', customer.toString()),
      });

      return response.data;
    },
    [request]
  );

  const exportCustomers = useCallback(async () => {
    const response = await request<{ message: string }>({
      method: 'post',
      url: endpoints.stores.customers.export,
    });

    return response.data;
  }, [request]);

  const syncStock = useCallback(
    async (store: number) => {
      const response = await request<{ task_id: string }>({
        method: 'post',
        url: endpoints.stores.syncStock.replace(':id', store.toString()),
      });

      return response.data;
    },
    [request]
  );

  const setPlatform = useCallback(
    async (platform: string, store: number) => {
      const response = await request({
        method: 'post',
        url: endpoints.stores.setPlatform.replace(':id', store.toString()),
        data: {
          platform: platform || null,
        },
      });

      return response.data;
    },
    [request]
  );

  return {
    listStores,
    getStore,
    inactivateStore,
    activateStore,
    createStore,
    updateStore,
    listFaq,
    getFaq,
    createFaq,
    updateFaq,
    deleteFaq,
    listCustomers,
    getCustomer,
    exportCustomers,
    syncStock,
    setPlatform,
  };
};

export default useStores;
