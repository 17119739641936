import { useCallback } from 'react';

import { useApi, endpoints } from '@contexts/Api';

import { enums } from '@utils';

const useAuthentication = () => {
  const { request } = useApi();

  const getCitiesByState = useCallback(
    async <CityFields extends keyof City>(params: CitiesQuery) => {
      const response = await request<Array<Pick<City, CityFields>>>({
        method: 'get',
        url: endpoints.core.cities.list,
        params: {
          fields: params.fields.join(','),
          state: params.state,
          paginate: false,
        },
      });

      return response.data;
    },
    [request]
  );

  const getTask = useCallback(
    async (task: string) => {
      const response = await request<[string, enums.TaskStatus]>({
        method: 'get',
        url: endpoints.core.tasks.get.replace(':id', task),
      });

      return response.data;
    },
    [request]
  );

  return {
    getCitiesByState,
    getTask,
  };
};

export default useAuthentication;
