import React, { useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { useCore, useStores } from '@hooks';

import Loading from '@components/Loading';

import { enums } from '@utils';

import ConfigForm from './ConfigForm';
import PlatformForm from './PlatformForm';

interface IntegrationProps {
  store: Store;
}

const Integration: React.FC<IntegrationProps> = ({ store }) => {
  const [taskId, setTaskId] = useState<string>();

  const { getTask } = useCore();
  const { syncStock } = useStores();

  const { isLoading: isLoadingLastTaskCheck } = useQuery(
    ['task', store.last_task_id],
    () => getTask(store.last_task_id!),
    {
      onSuccess: (data) => {
        if (data[1] == enums.TaskStatus.PENDING) {
          setTaskId(store.last_task_id!);
        }
      },
      enabled: !!store.last_task_id,
    }
  );

  const { mutate: requestSyncStock } = useMutation(syncStock, {
    onSuccess: (data) => {
      setTaskId(data.task_id);
    },
  });

  return (
    <>
      {isLoadingLastTaskCheck ? (
        <Loading />
      ) : (
        <>
          <PlatformForm
            store={store}
            taskId={taskId}
            onRequest={() => requestSyncStock(store.id)}
            onComplete={() => setTaskId(undefined)}
          />
          <ConfigForm store={store} />
        </>
      )}
    </>
  );
};

export default Integration;
