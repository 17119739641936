import styled, { css, keyframes } from 'styled-components';

import Button from '@components/Button';

const rotate = keyframes`
100% {
  transform: rotate(360deg);
}
`;

export const CustomButton = styled(Button)<{ syncing: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.two};
  align-self: flex-end;

  ${({ syncing }) =>
    syncing &&
    css`
      svg {
        animation: ${rotate} 1s infinite;
      }
    `}
`;
