import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import { useInfiniteQuery } from 'react-query';
import { getNextPageParam } from 'utils/helpers';

import { useToast } from '@contexts/Toast';
import { useIntegrations } from '@hooks';

import FormError from '@components/FormError';
import FormGroup from '@components/FormGroup';
import SearchSelect, {
  SearchSelectProps,
  SelectOption,
} from '@components/SearchSelect';

type IntegrationSelectProps = Omit<
  SearchSelectProps<SelectOption>,
  | 'name'
  | 'onSearch'
  | 'options'
  | 'value'
  | 'onChange'
  | 'isLoading'
  | 'fetchMore'
  | 'onBlur'
  | 'invalidValue'
>;

const IntegrationSelect: React.FC<IntegrationSelectProps> = (props) => {
  const [search, setSearch] = useState('');

  const formik = useFormikContext<IntegrationFormValues>();

  const { listIntegrations } = useIntegrations();
  const { addToast } = useToast();

  const {
    data: integrations,
    isLoading,
    fetchNextPage,
  } = useInfiniteQuery(
    ['integrations', search],
    ({ pageParam = 1 }) =>
      listIntegrations({
        fields: ['id', 'logo', 'name'],
        page: pageParam,
        search,
      }),
    {
      getNextPageParam,
      onError: () => {
        addToast('Falha ao carregar as integrações.', 'error');
      },
    }
  );

  return (
    <FormGroup>
      <SearchSelect
        name="integration"
        onSearch={setSearch}
        options={
          integrations?.pages
            .flatMap((page) => page.results)
            .map((integration) => ({
              label: integration.name,
              value: integration.id.toString(),
            })) || []
        }
        value={formik.values.integration.label}
        onChange={(value) => {
          setSearch('');
          formik.setFieldValue('integration', value);
        }}
        isLoading={isLoading}
        fetchMore={fetchNextPage}
        onBlur={() => formik.setFieldTouched('integration.value', true)}
        invalidValue={
          !!formik.touched.integration?.value &&
          !!formik.errors.integration?.value
        }
        onClear={() => {
          formik.setFieldValue('integration', '');
        }}
        {...props}
      />
      <FormError name="integration.value" />
    </FormGroup>
  );
};

export default IntegrationSelect;
