import React, { useRef } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import BaseForm from '@components/BaseForm';
import PageHeader from '@components/PageHeader';

import RoutesPath from '@router/routes';

import Address from '../Form/Address';
import ClientUserInfo from '../Form/ClientUserInfo';
import routes from './routes';

const CreateClient: React.FC = () => {
  const clientUserInfoRef = useRef<FormStepRef>(null);
  const clientAddressInfoRef = useRef<FormStepRef>(null);

  const { createStore } = useStores();
  const { addToast } = useToast();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createRequest = useMutation(createStore, {
    onSuccess: (data) => {
      addToast('Cliente criado com sucesso', 'success');
      if (data.document.length === 11) {
        navigate(RoutesPath.private.clients.list.physical, { replace: true });
      } else {
        navigate(RoutesPath.private.clients.list.legal, { replace: true });
      }
      queryClient.invalidateQueries('stores');
    },
  });

  return (
    <>
      <PageHeader title="Cadastro de cliente" routes={routes} />
      <BaseForm
        steps={[
          {
            title: 'Dados do cliente',
            ref: clientUserInfoRef,
            component: ClientUserInfo,
          },
          {
            title: 'Dados da localização',
            ref: clientAddressInfoRef,
            component: Address,
          },
        ]}
        createRequest={createRequest}
        saveEveryStep={true}
      />
    </>
  );
};

export default CreateClient;
