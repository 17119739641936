import React from 'react';

import Tabs from '@components/Tabs';

import RoutesPath from '@router/routes';
import { enums } from '@utils';

import Charges from '../Charges';
import Integration from '../Integration';
import PostsOfStore from '../PostsOfStore';
import StoreDetail from '../StoreDetail';
import UsersOfStore from '../UsersOfStore';

interface LegalClientTabsProps {
  store: Store;
  privilege?: Pick<Privilege, 'id' | 'plan'>;
  subscription?: Pick<Subscription, 'id' | 'plan'>;
}

const LegalClientTabs: React.FC<LegalClientTabsProps> = ({
  store,
  privilege,
  subscription,
}) => {
  return (
    <Tabs
      tabs={[
        {
          title: 'Loja',
          component: (
            <StoreDetail
              store={store}
              storeType={enums.StoreType.LEGAL}
              privilege={privilege}
              subscription={subscription}
            />
          ),
          path: RoutesPath.private.clients.detail.legal.detail.replace(
            ':clientId',
            store.id.toString()
          ),
        },
        {
          title: 'Anúncios',
          component: <PostsOfStore />,
          path: RoutesPath.private.clients.detail.legal.posts.list.replace(
            ':clientId',
            store.id.toString()
          ),
        },
        {
          title: 'Usuários',
          component: <UsersOfStore />,
          path: RoutesPath.private.clients.detail.legal.users.list.replace(
            ':clientId',
            store.id.toString()
          ),
        },
        {
          title: 'Cobranças',
          component: (
            <Charges store={store.id} storeType={enums.StoreType.LEGAL} />
          ),
          path: RoutesPath.private.clients.detail.legal.charges.replace(
            ':clientId',
            store.id.toString()
          ),
        },
        {
          title: 'Integração',
          component: <Integration store={store} />,
          path: RoutesPath.private.clients.detail.legal.integration.replace(
            ':clientId',
            store.id.toString()
          ),
          disabled: !store.is_active,
        },
      ]}
    />
  );
};

export default LegalClientTabs;
