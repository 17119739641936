import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: auto;
  margin-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray.two};
`;

export const Title = styled.div<{ isSelected: boolean; disabled: boolean }>`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) => props.theme.colors.gray.six};
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 0px 10px;

  ${(props) =>
    props.isSelected &&
    css`
      font-weight: ${(props) => props.theme.fontWeight.bold};
      color: ${props.theme.colors.black.two};
      border-bottom: 2px solid ${props.theme.colors.main};
      transition: all 0.2s 0s ease;
      cursor: default;
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${props.theme.colors.gray.one};
      cursor: not-allowed;
    `}
`;
