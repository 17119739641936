import React from 'react';

import { AxiosError } from 'axios';
import { transformObjectInArray } from 'b2utils';
import { FormikProvider, useFormik } from 'formik';
import { B2Card } from 'react-b2components';
import { useMutation, useQueryClient } from 'react-query';
import * as yup from 'yup';

import { useToast } from '@contexts/Toast';
import { useStores } from '@hooks';

import Button from '@components/Button';

import IntegrationSelect from './IntegrationSelect';
import SyncButton from './SyncButton';
import { ButtonContainer, Header } from './styles';

interface PlatformFormProps {
  store: Store;
  taskId?: string;
  onRequest: () => void;
  onComplete: () => void;
}

const PlatformForm: React.FC<PlatformFormProps> = ({
  store,
  taskId,
  onRequest,
  onComplete,
}) => {
  const { setPlatform } = useStores();
  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const formikInitialValues: IntegrationFormValues = {
    integration: {
      label: store.current_platform?.platform.name ?? '',
      value: store.current_platform?.platform.id.toString() ?? '',
    },
  };

  const formikValidationSchema = yup.object().shape({
    integration: yup.object().shape({
      value: yup.string(),
    }),
  });

  const setPlatformRequest = useMutation(
    (data: IntegrationFormValues) =>
      setPlatform(data.integration.value, store.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['store', store.id]);
        addToast('Integração salva com sucesso');
      },
      onError: (error: AxiosError) => {
        if (error.response?.data) {
          const messages = transformObjectInArray(error.response.data);
          messages.forEach((message) => addToast(message, 'error'));
        }
      },
    }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formikInitialValues,
    validationSchema: formikValidationSchema,
    onSubmit: (values) => setPlatformRequest.mutate(values),
  });

  return (
    <B2Card>
      <Header>
        <p>Integração</p>
        <SyncButton
          disabled={!store.current_platform}
          taskId={taskId}
          onRequest={onRequest}
          onComplete={onComplete}
        />
      </Header>
      <FormikProvider value={formik}>
        <IntegrationSelect />
        <ButtonContainer>
          <Button
            type="submit"
            onClick={() => formik.handleSubmit()}
            isLoading={setPlatformRequest.isLoading}
          >
            Salvar
          </Button>
        </ButtonContainer>
      </FormikProvider>
    </B2Card>
  );
};

export default PlatformForm;
