import React, { useMemo, useRef } from 'react';

import { B2Card } from 'react-b2components';
import { useMutation } from 'react-query';

import { useToast } from '@contexts/Toast';
import { useIntegrations } from '@hooks';

import Button from '@components/Button';

import { ButtonContainer } from '../PlatformForm/styles';
import integrationsComponents from './integrationsComponents';

interface ConfigFormProps {
  store: Store;
}

const ConfigForm: React.FC<ConfigFormProps> = ({ store }) => {
  const configComponentRef = useRef<FormStepRef>(null);

  const ConfigComponent = useMemo(() => {
    const integration = store.current_platform;

    if (!integration) {
      return null;
    }

    return integrationsComponents[integration.platform.name];
  }, [store?.current_platform]);

  const { configureIntegration } = useIntegrations();
  const { addToast } = useToast();

  const requestIntegrationConfiguration = useMutation(
    (data: FormValuesIntegrationConfig) =>
      configureIntegration(
        store!.current_platform!.platform.id,
        store.id,
        data
      ),
    {
      onSuccess: () => {
        addToast('Dados atualizados com sucesso!', 'success');
      },
      onError: () => {
        addToast('Falha ao atualizar os dados, tente novamente', 'error');
      },
    }
  );

  return (
    <B2Card>
      <p>Configurações</p>
      {ConfigComponent ? (
        <>
          <ConfigComponent
            ref={configComponentRef}
            requestIntegrationConfiguration={requestIntegrationConfiguration}
            initialValues={{
              url: store.current_platform?.stock_url ?? undefined,
              token: store.current_platform?.token ?? undefined,
            }}
          />
          <ButtonContainer>
            <Button
              type="submit"
              onClick={() => configComponentRef.current?.submit()}
              isLoading={requestIntegrationConfiguration.isLoading}
            >
              Salvar
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <p>Integração indisponível ou não selecionada</p>
      )}
    </B2Card>
  );
};

export default ConfigForm;
