import React from 'react';

import { enums } from '@utils';

import Lua4Config from './Lua4Config';
import WebmotorsConfig from './WebmotorsConfig';

const integrationsComponents: {
  [key in enums.Platforms]: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<IntegrationConfigProps> &
      React.RefAttributes<FormStepRef>
  >;
} = {
  [enums.Platforms.LUA4]: Lua4Config,
  [enums.Platforms.WEBMOTORS]: WebmotorsConfig,
};

export default integrationsComponents;
