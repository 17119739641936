import { useCallback } from 'react';

import { endpoints, useApi } from '@contexts/Api';

const useIntegrations = () => {
  const { request } = useApi();

  const listIntegrations = useCallback(
    async <IntegrationFields extends keyof Platform>(
      params: PlatformsQuery
    ) => {
      const response = await request<Paginated<Platform, IntegrationFields>>({
        method: 'get',
        url: endpoints.integrations.list,
        params: {
          fields: params.fields.join(','),
          page: params.page,
          search: params.search || undefined,
        },
      });

      return response.data;
    },
    [request]
  );

  const configureIntegration = useCallback(
    async (
      platform: number,
      store: number,
      data: FormValuesIntegrationConfig
    ) => {
      const response = await request<void>({
        method: 'post',
        url: endpoints.integrations.configure.replace(
          ':id',
          platform.toString()
        ),
        data: {
          store,
          config: data.url ?? data.token,
        },
      });

      return response.data;
    },
    [request]
  );

  return { listIntegrations, configureIntegration };
};

export default useIntegrations;
