export const endpoints = {
  auth: {
    login: '/auth/login/',
    profile: '/auth/profile/',
    register: '/auth/register/',
    resetPassword: '/auth/reset-password/',
    updatePassword: '/auth/update-password/',
    confirmChangePassword: '/auth/confirm-change-password/',
    confirmEmail: '/auth/confirm-email/',
    systemManagers: {
      list: '/auth/system-managers/',
      get: '/auth/system-managers/:id/',
      post: '/auth/system-managers/',
      patch: '/auth/system-managers/:id/',
      inactivate: '/auth/system-managers/:id/inactivate/',
      activate: '/auth/system-managers/:id/activate/',
    },
    storeUsers: {
      list: '/auth/store-users/',
      get: '/auth/store-users/:id/',
      inactivate: '/auth/store-users/:id/inactivate/',
      activate: '/auth/store-users/:id/activate/',
    },
    updateEmail: '/auth/update-email/',
  },
  assets: {
    files: {
      post: '/assets/files/',
      presignedUrl: {
        get: '/assets/files/presigned-url/',
      },
    },
    images: {
      post: '/assets/images/',
      presignedUrl: {
        get: '/assets/images/presigned-url/',
      },
    },
  },
  posts: {
    vehicleCategories: {
      list: '/posts/vehicle-categories/',
      get: '/posts/vehicle-categories/:id/',
    },
    brands: {
      list: '/posts/brands/',
    },
    models: {
      list: '/posts/models/',
    },
    modelVersions: {
      list: '/posts/model-versions/',
    },
    tags: {
      list: '/posts/tags/',
    },
    vehicleConditions: {
      list: '/posts/vehicle-conditions/',
      patch: '/posts/vehicle-conditions/:id/',
    },
    images: {
      post: '/posts/images/',
      list: '/posts/images/',
      delete: '/posts/images/:id/',
    },
    suggestedPrices: {
      get: '/posts/:id/suggested-prices/',
    },
    fipeTable: {
      get: '/posts/:id/fipe-tables/',
    },
    list: '/posts/',
    get: '/posts/:id/',
    post: '/posts/',
    patch: '/posts/:id/',
    complete: '/posts/:id/complete/',
    delete: '/posts/:id/',
    inactivate: '/posts/:id/inactivate/',
    activate: '/posts/:id/activate/',
    customFields: {
      list: '/posts/custom-fields/',
    },
    customFieldAnswers: {
      list: '/posts/custom-field-answers/',
      answer: '/posts/custom-field-answers/answer/',
    },
    inspect: {
      post: '/posts/:id/inspect/',
      delete: '/posts/:id/inspect/',
    },
    approve: '/posts/:id/approve/',
    reject: '/posts/:id/reject/',
    plate: '/posts/plate/',
    chassis: '/posts/chassis/',
    bulkingApprove: '/posts/bulking-approve/',
  },
  stores: {
    list: '/stores/',
    get: '/stores/:id/',
    inactivate: '/stores/:id/inactivate/',
    activate: '/stores/:id/activate/',
    post: '/stores/',
    patch: '/stores/:id/',
    syncStock: '/stores/:id/sync-stock/',
    setPlatform: '/stores/:id/set-platform/',
    faq: {
      list: '/stores/faq/',
      get: '/stores/faq/:id/',
      post: '/stores/faq/',
      patch: '/stores/faq/:id/',
      delete: '/stores/faq/:id/',
    },
    customers: {
      list: '/stores/customers/',
      get: '/stores/customers/:id/',
      export: '/stores/customers/export/',
    },
  },
  core: {
    cities: {
      list: '/core/cities/',
    },
    tasks: {
      get: '/core/tasks/:id/',
    },
  },
  advertisements: {
    list: '/advertisements/',
    get: '/advertisements/:id/',
    post: '/advertisements/',
    patch: '/advertisements/:id/',
    delete: '/advertisements/:id/',
  },
  payments: {
    plans: {
      list: '/payments/plans/',
      get: '/payments/plans/:id/',
      post: '/payments/plans/',
      inactivate: '/payments/plans/:id/inactivate/',
      activate: '/payments/plans/:id/activate/',
      patch: '/payments/plans/:id/',
    },
    subscriptions: {
      list: '/payments/subscriptions/',
      delete: '/payments/subscriptions/:id/',
    },
    vouchers: {
      list: '/payments/vouchers/',
      patch: '/payments/vouchers/:id/',
    },
    charges: {
      list: '/payments/charges/',
    },
    privileges: {
      post: '/payments/privileges/',
      list: '/payments/privileges/',
      delete: '/payments/privileges/:id/',
    },
    credits: {
      balance: {
        get: '/payments/credits/balance/',
      },
      charity: '/payments/credits/charity/',
    },
  },
  analytics: {
    charges: {
      balance: '/analytics/charges/balance/',
    },
    subscriptions: {
      count: '/analytics/subscriptions/count/',
      histogram: '/analytics/subscriptions/histogram/',
    },
    post: {
      activeCount: '/analytics/post/active-count/',
      avgClicksInPhone: '/analytics/post/avg_clicks_in_phone',
    },
    stores: {
      activeHistogram: '/analytics/stores/active_histogram/',
    },
  },
  integrations: {
    list: '/integrations/',
    addStore: '/integrations/:id/add-store/',
    configure: '/integrations/:id/configure/',
  },
};

export const brasilApiEndpoints = {
  cep: {
    get: '/cep/v1/:cep',
  },
};
